.inputText {
  width: 300px;
  height: 40px;
  font-size: 20px !important;

}

body {
 overflow-y: auto;
 overflow-x: none;
  background-color: whitesmoke;

}
#top_menu ul{
  margin-top: 20px;
  margin-left: 12px;
}

/*.mob:hover{
  box-shadow: red 60px -16px;
}*/
/*.page-container {
  width: 210mm; /* Largeur A4 */
/*height: 297mm; /* Hauteur A4 */
/*margin: 20px auto; /* Centrer la page */
/*border: 1px solid #ccc; /* Bordure pour l'aperçu */
/*overflow: hidden;

}*/

/* Ajoutez ici votre contenu pour l'aperçu 
.content {
  padding: 20px;
  background-color:'white'
}*/

.editorComponent_apercu2{
  overflow: hidden;
  min-height: 100px;
  width: 100%;
  position: absolute;

}
#pageA4 {
  width: 210mm;
  min-height: 297mm;
  background: white;
  margin: auto;


}

#btnRecord {
    width: 80px;
    height: 80px;
    border: 1px solid white;
    position: absolute;
    border-radius: 100px;
    background-color:white;
    margin-top:20;
    z-index:1;
    margin-left: 90%;
}
#btnRecord img{
  width: 100%; 
  height:100%;
  cursor:pointer;
  border-radius: 100;
  position:absolute;
  background-color: transparent;
}
#btnRecord:hover {
  border: 2px solid red;
}
#main_block{
  margin-top: 40px;
}
#optionCR{
  position: absolute;
  margin-top:-50px;
}
.bouleanimate{
  animation: zoom 1s ease-out infinite; 
  
}
@keyframes zoom {
  from  {
    width:0px;
    height: 0px;
  }
  to {
    width:10px;
    height: 10px;
  }
}
@media screen {
  #pageA4 {
    box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
    min-height: 297mm;
    width: 210mm;


  }

  #text {
    padding: 12.7mm;
    /* A l'impression il y a des marges auto de 12.7mm */
  }
}

#btn_dd button i {

  font-size: 25;
  color: #4ECDC4
}

#btn_dd button:hover {
  background-color: aqua;
  color: white;
  transition: 2s all ease-in-out;
}

#btn_dd button i:hover {

  color: white;

}


@media print {

  body,
  #pageA4 {

    margin-top: 40px;
  }

  #pageA4 {
    width: 210mm;
    height: calc(280mm - 25.4mm);
  }

  #text {
    padding: 0px;
  }
}

.btn-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px;
}

.clc-tooltip {
  /* display: inline-block; */
  position: relative;
  margin-top: -16px;
  background-color: #3b4045;
  color: white;
  border-radius: 2px;
  padding: 3px 5px;
  visibility: hidden;
  white-space: nowrap;
  cursor: default;
  font-size: 12px;
  box-shadow: 0 0 1px #fff, 0 0 5px rgba(0, 0, 0, 0.3)
}

.clc-tooltip:after {
  content: "";
  border: 4px transparent solid;
  position: relative;
  margin-top: 20px;
}

.clc-tooltip.above-left {
  /* right: 0; */
  left: 0;
  transform: translateY(-100%);
  top: -5px
}

.clc-tooltip.above-left:after {
  /* right: 0; */
  left: 5px;
  transform: translateX(-4px);
  top: 100%;
  border-top-color: #3b4045
}

.clc-tooltip.left {
  top: calc(50% + 2px);
  transform: translateY(-50%);
  /* right: calc(100% + 4px + 3px) */
  left: calc(100% + 4px + 3px)
}

.clc-tooltip.left:after {
  /* right: -12px; */
  left: -12px;
  transform: translateX(-4px);
  top: 5px;
  border-left-color: #3b4045
}

.clc-dismiss {
  position: relative;
  /* right: 6px;
  top: 6px; */
  cursor: pointer;
  opacity: 1;
  width: 16px;
  height: 16px;
  display: block;
  background-color: #fff;
  text-decoration: none;
  box-shadow: 0 0 1px 1px rgba(255, 255, 255, 0.75);
  border-radius: 8px;
  z-index: 2
}

.clc-dismiss:hover .clc-tooltip {
  visibility: visible
}

.clc-dismissable:hover .clc-dismiss {
  opacity: .5;
  transition: opacity .15s ease-in-out
}

.clc-dismissable:hover .clc-dismiss:hover {
  opacity: 1
}

.table-responsive .table .text-primary th {
  font-weight: bold;
}

.disabled {
  pointer-events: none;
  cursor: default;
  color: red;
}

.editor {
  position: absolute;
  max-height: 58vh;
  min-height: 58vh;
  height: auto;
  width: 100%;
  top: -30px;
  right: 15px !important;
  background-color: white;
  border-collapse: separate;
  border: 1px solid rgb(204, 204, 204);
  padding: 10px;
  box-sizing: content-box;
  -webkit-box-shadow: rgba(0, 0, 0, 0.0745098) 0px 1px 1px 0px inset;
  box-shadow: rgba(0, 0, 0, 0.0745098) 0px 1px 1px 0px inset;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
  overflow: auto;
  outline: none;
}

.btn-toolbar {
  position: relative;
  width: 100%;
  top: -78px;
  right: 56px !important;
}

#voiceBtn {
  width: 20px;
  color: transparent;
  background-color: transparent;
  transform: scale(2.0, 2.0);
  -webkit-transform: scale(2.0, 2.0);
  -moz-transform: scale(2.0, 2.0);
  border: transparent;
  cursor: pointer;
  box-shadow: none;
  -webkit-box-shadow: none;
}

div[data-role="editor-toolbar"] {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.dropdown-menu a {
  cursor: pointer;
}

.btn-toolbar,
.editor {
  display: none;
}
.editorComponent_apercu{
  pointer-events:none;
  cursor: 'none';
  border:2px solid black;
 

}

